<template>
  <div class=" font-test">
    <!-- 顶部菜单栏和视频区 -->
    <div class="container">
      <aboutsPageTopMb />
    </div>
    <div class="content-box app-container">
      <!-- <el-image :src="contentList[language]" width="100%" lazy></el-image> -->
      <p v-if="language =='zh'" class="company-announcement">{{ $t('企业公告') }}</p>
      <p v-if="language =='zh_tw'" class="company-announcement">{{ $t('企業公告') }}</p>
      <p v-if="language =='en'" class="company-announcement">{{ $t('News') }}</p>
      <p v-if="language =='ja'" class="company-announcement">{{ $t('ニュース') }}</p>
      <p v-if="language =='ko'" class="company-announcement">{{ $t('기업 뉴스') }}</p>
      <div class="">
        <div class="company-box">
          <!-- <a class="company-content" v-if="language =='zh'" href="/pdf/TRAVEL-SC.pdf" target="_blank" > -->
          <div class="company-content" v-for="(item,id) in companyAnno2" :key="id" @click="pageHandle('/news-20220420')">
            <div class="contentList-box">
              <div class="mask" v-if="mask"></div>
              <el-image :src="item.url[language]" width="100%" lazy></el-image>
            </div>
            <div class="contentList-text">
              <p class="contentList-title">{{ item.title[language] }}</p>
              <p class="contentList-date">
                <span>{{ item.date[language] }}</span>
                <span class="contentList-detail">{{ item.detail[language] }}</span>
              </p>
            </div>
          </div>
          <!-- </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aboutsPageTopMb from '@/components/aboutsPage-top-mb'
import { homeLanList, homePhBannerList, companyAnno2 } from '@/utils/public_data'
import { reloadMessage } from "@/lang"
export default {
  data() {
    return {
      logo: require("@/assets/images/customer/PC-home.png"),
      activeIndex: '2',
      lanLogo: require("@/assets/images/customer/PC_line.png"),
      lanList: homeLanList(),
      contentList: homePhBannerList(),
      //  contentList:require("@/assets/images/banner/phone/banner-9.png"),
      mask: false,
      companyAnno2: companyAnno2()
    }
  },
  components: {
    aboutsPageTopMb
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
  }
}
</script>
<style lang='scss' scoped>
.container {
  width: 100%;
}
.company-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.company-content {
  display: block;
  margin: 0 16px;
  cursor: pointer;
}
.company-announcement {
  width: 92%;
  font-family: "GenJyuuGothic";
  font-weight: 400;
  font-size: 16px;
  line-height: 27.96px;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #fcb425;
}
.contentList-box {
  width: 156px;
  height: 117px;
  background: transparent;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}
.mask {
  width: 156px;
  height: 117px;
  background-color: #fff;
  opacity: 0.3;
  position: absolute;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.contentList-title {
  font-family: "GenJyuuGothic";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 20px;
  text-align: left;
  color: #000;
  margin-bottom: 20px;
}
.contentList-text {
  width: 156px;
}
.contentList-date {
  font-family: "GenJyuuGothic";
  font-weight: 400;
  font-size: 12px;
  line-height: 19.2px;
  text-align: left;
  color: #fcb425;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentList-detail {
  display: block;
  width: auto;
  height: 20px;
  background: #fef1d6;
  border: 0.75px solid #fcb425;
  border-radius: 20px;
  text-align: center;
  line-height: 20px;
  color: #fcb425;
  cursor: pointer;
  font-size: 12px;
  padding: 0 6px;
}
.contentList-detail:active {
  color: #fcb425;
}
.font-test {
  font-family: "GenJyuuGothic";
}
.app-container {
  width: 100%;
  margin: 0 auto;
}

.test {
  z-index: 0;
}
.menu-text2 {
  text-align: center;
  overflow: hidden;
  color: #333;
  font-size: 16px;
}
.menu-text2:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.font-14 {
  font-size: 14px;
}
.color-green {
  color: #6aaf38;
}
.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-line {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.top-banner {
  position: relative;
  height: 44px;
  z-index: 0;
  overflow: hidden;
  .top-banner-bg {
    position: absolute;
    width: 100%;
    height: 56px;
    left: 0;
    top: 80px;
    background: url("../../assets/images/bg/bg6.png") no-repeat;
    z-index: -11;
  }
  .top-banner-bg2 {
    position: absolute;
    right: -100px;
    top: -100px;
    width: 600px;
    height: 600px;
    background: #ffc64e;
    transform: rotate(21deg);
    border-radius: 40px;
    z-index: -1;
  }
}

.top-menu-box {
  background: #fff;
}
.top-menu {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-img {
    width: 25px;
    cursor: pointer;
    margin-left: 10px;
  }
  .lan-logo {
    width: 21px;
  }
}
.bg-t {
  background: rgba(255, 255, 255, 0);
}
.el-menu--horizontal .el-menu .el-menu-item {
  padding: 0 !important;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  display: none;
}
::v-deep .el-submenu__title {
  padding: 0 !important;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(255, 255, 255, 0);
  // color: #FFBB2C;
}
.el-menu--horizontal > .el-menu-item.is-active {
  // border-bottom: 2px solid #fff;
  color: #ffbb2c;
}
// .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title,  .el-menu--horizontal .el-menu .el-submenu__title:hover{
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-submenu {
//   background-color: rgba(255,255,255,0);
// }
// :v-deep .el-submenu__title {
//   color: #fff;
//   background-color: rgba(255,255,255,0);
// }
// .el-submenu__title:hover {
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-menu {
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-menu-item {
//   color: #fff;
//   background-color: rgba(255,255,255,0);
//   &:hover, &:focus {
//     background-color: rgba(255,255,255,0);
//   }
// }
::v-deep .el-menu--popup {
  // background-color: rgba(255,255,255,0);
  min-width: 100px;
}
::v-deep .el-input__inner {
  border: 0;
}
.searchForm-box {
  box-sizing: border-box;
  padding: 16px 30px;
  width: 1000px;
  height: 100px;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  border-radius: 15px;
  -moz-box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
  -webkit-box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
  box-shadow: 2px 2px 10px rgba(248, 182, 42, 0.5);
  .searchForm-title {
    display: flex;
    justify-content: space-between;
  }
}
.searchForm {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #333;
  border-radius: 5px;
  background: #fff4dc;
  .border-line {
    border-right: 1px solid #333;
  }
}
.near {
  cursor: pointer;
  // &:hover {
  //   color: #E7B530;
  // }
  line-height: 40px;
  padding: 0px 20px;
  text-align: center;
  font-size: 24px;
  // font-weight: 600;
  color: #333;
}
.video-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1000px;
  height: 534px;
  margin: 24px auto 0px;
  background: #fff;
  border: 2px solid #dcdfe6;
  border-radius: 50px;
  z-index: 1000;
  .video-content {
    width: 880px;
    height: 496px;
    z-index: 100;
  }
}
.video-icon {
  width: 34px;
  cursor: pointer;
}
.bulletin-box {
  display: flex;
  width: 1000px;
  height: 45px;
  border: 1px solid #f8b62a;
  border-radius: 15px;
  .bulletin-left {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    width: auto;
    height: 100%;
    background: #f8b62a;
    border-radius: 15px;
    color: #fff;
  }
}
.center-box {
  position: relative;
  width: 100%;
  background: #fff4dc;
  background-size: 140% 100%;
  background-position: -700px 0px;
  z-index: 0;
  .center-bg {
    margin-top: 30px;
    background: #f9f9f9;
    height: 1286px;
    overflow: hidden;
    z-index: 0;
    .center-bg1 {
      position: absolute;
      left: 0;
      top: 0px;
      width: 260px;
      height: 900px;
      background: url("../../assets/images/bg/bg2.png") no-repeat;
      z-index: 1;
    }
    .center-bg2 {
      position: absolute;
      top: 200px;
      right: 0;
      width: 280px;
      height: 550px;
      background: url("../../assets/images/bg/bg4.png") no-repeat;
      z-index: 1;
    }
    .center-bg3 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 280px;
      height: 418px;
      background: url("../../assets/images/bg/bg3.png") no-repeat;
      z-index: 1;
    }
  }
}
.tab-box {
  margin-top: 30px;
  display: flex;
  ::v-deep .el-tabs__active-bar {
    color: #f8b62a;
    background-color: #f8b62a;
  }
  ::v-deep .is-active {
    color: #f8b62a;
    font-size: 6px;
  }
  ::v-deep .el-tabs__item {
    text-align: center;
    padding: 0px 10px;
    margin: 0;
    height: 45px;
    &:hover {
      color: #f8b62a;
    }
  }
  ::v-deep .is-active {
    color: #f8b62a;
  }
  ::v-deep .el-tabs__active-bar {
    text-align: center;
    margin: 0 auto;
    height: 4px;
    color: #f8b62a;
    background-color: #f8b62a;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    background-color: rgba(255, 255, 255, 0);
  }
  .tab-title {
    padding: 0px 20px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
}
.content-box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 100;
  .left-banner {
    width: 486px;
    height: 1078px;
    border: 2px solid #e7b530;
    border-radius: 50px;
    z-index: 100;
    .left-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: 0;
      width: 100%;
      height: 68px;
      border-radius: 47px 47px 0 0;
      background: #e7b530;
      color: #fff;
      font-size: 24px;
      background: url("../../assets/images/bg/bg5.png");
    }
  }
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #ffbb2c;
}
.swiper {
  margin-top: 30px;
  width: 100%;
  height: 900px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  .swiper-slide {
    text-align: center;
    width: 400px;
    height: 250px;
  }
}
.bulletin-right {
  width: 90%;
}
.bulletin-swiper {
  margin-left: 20px;
  width: 100%;
  height: 45px;
  .swiper-slide {
    width: 100%;
    height: 45px;
  }
}
.menu-text {
  font-size: 16px;
  color: #333;
}
.right-list-box {
  width: 476px;
  z-index: 100;
  .right-title {
    display: flex;
    font-size: 23px;
    border-bottom: 3px solid #f8b62a;
    line-height: 20px;
    color: #f8b62a;
    padding-bottom: 13px;
    .right-img {
      margin-right: 20px;
      width: 30px;
      height: 24px;
    }
  }
  .right-list {
    width: 100%;
    .right-item {
      margin: 18px 0;
      width: 100%;
      height: 186px;
      border-radius: 25px;
      border: 2px solid #f8b62a;
      z-index: 100;
      cursor: pointer;
      .right-item-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #f8b62a;
        width: 100%;
        height: 40px;
        border: 0;
        padding: 0;
        padding-left: 20px;
        box-sizing: border-box;
        border-radius: 22px 22px 0 0;
        color: #fff;
        font-size: 19px;
      }
      .right-item-content {
        padding: 0 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 480px;
        height: 160px;
        .content-box {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 314px;
          height: 130px;
          margin-top: 20px;
          .list-info-item {
            line-height: 1.5;
            width: 314px;
            font-size: 14px;
          }
        }
        .img-box {
          width: 100px;
          height: 120px;
          .right-item-img {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}
.banner-item {
  border-radius: 35px;
  width: 400px;
  height: 250px;
  cursor: pointer;
}
.partner-box {
  width: 100%;
  height: 540px;
  background: #fff4dc;
  overflow: hidden;
  z-index: 1;
  .partner-title {
    margin-top: 78px;
    width: 1000px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    background-image: linear-gradient(to right, #fff4dc, #f8b62a, #fff4dc);
    font-size: 22px;
  }
}
.partner-list {
  margin-top: 41px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .partner-item {
    margin-right: 25px;
    width: 180px;
    height: 180px;
    background: #fbf8fb;
  }
}
.bulletin-content {
  width: 96%;
  padding: 0 10px;
  box-sizing: border-box;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.banner-btn {
  background: #ffbb2c;
  color: #fff;
  border: 1px solid #ffbb2c;
  border-radius: 0px 5px 5px 0;
}
</style>
<style lang="scss">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 100px;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #f8b62a;
}
</style>